<template>
  <div v-cus-loading="form.loading">
    <div v-if="model.registerSuccess === true">
      <el-container>
        <el-header>
          <el-row v-cus-loading="form.loading" type="flex" justify="center" align="middle">
            <el-col :xs="22" :sm="20" :md="14" :lg="10" :xl="7" class="text-center">
              <div style="color: var(--classyPalette2); font-weight: bold" class="mt-20 mb-30">
                {{ $tc('registerMessageTitle') }}
              </div>
              <div style="color: var(--classyPalette2)" class="mt-30 mb-20">
                <div
                  v-html="
                    $t('registerMessage', 0, {
                      email: form.model.email.trim(),
                    })
                  "
                ></div>
                <div
                  v-html="
                    $t('registerMessageHelper', 0, {
                      email: form.model.email.trim(),
                    })
                  "
                ></div>
              </div>
              <div class="card-container">
                <div class="card-column-no-bordered">
                  <div class="card">
                    <div class="content">
                      <el-button class="btn btn-select" @click="onGoToService()">
                        <span>
                          {{ $tc('startWithAccount', 2) }}
                        </span>
                      </el-button>
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-header>
        <el-main> </el-main>
      </el-container>
    </div>
    <div v-if="model.registerSuccess === false">
      <el-row type="flex" justify="center">
        <el-col :xs="22" :sm="20" :md="14" :lg="10" :xl="7">
          <el-form ref="formUser" :model="form.model" :rules="form.rules">
            <el-form-item prop="email" :label="$tc('email')">
              <el-input
                id="id_userEmail"
                v-model.trim="form.model.email"
                :maxlength="128"
                :controls="false"
                :placeholder="`${$tc('email')}, ${$tc('exampleUserEmail')}`"
                @change="onChangeEmail"
              >
                <i slot="prefix" class="el-input__icon fa fa-envelope-square"></i>
              </el-input>
            </el-form-item>
            <el-form-item :label="$tc('fullName')" prop="fullName">
              <el-input
                id="id_userFullName"
                v-model="form.model.fullName"
                :maxlength="128"
                :controls="true"
                :placeholder="$tc('fullName')"
                auto-complete="off"
              >
                <i slot="prefix" class="el-input__icon fa fa-portrait"></i>
              </el-input>
            </el-form-item>
            <el-form-item :label="$tc('phoneCell')" prop="phone">
              <el-input
                id="id_phone"
                v-model.trim="form.model.phone"
                :controls="false"
                :placeholder="$tc('phoneCell')"
              >
                <i slot="prefix" class="el-input__icon fa fa-phone-volume"></i>
              </el-input>
            </el-form-item>
            <el-form-item :label="$tc('institution')" prop="company.companyStudentInstitution">
              <select-institutions
                v-model="form.model.company.companyStudentInstitutionCode"
                name="companyStudentInstitutionCode"
                @input="onValidateIdentificationPattern($event)"
                @set-name="form.model.company.companyStudentInstitutionName = $event"
                @set-code="form.model.company.companyStudentInstitutionCode = $event"
              ></select-institutions>
            </el-form-item>
            <el-form-item prop="company.companyStudentInstitution" style="margin-top: 1.5em">
              <el-upload
                ref="upload"
                v-model="form.model.studentCard"
                accept="image/*,.pdf"
                :action="uriStudentCardFile"
                :auto-upload="false"
                :on-change="onChangeUpload"
                drag
              >
                <i class="fad fa-cloud-upload fa-2x"></i>
                <div class="el-upload__text">
                  <small>
                    {{ $tc('dropHereStudentCard') }}
                  </small>
                </div>
              </el-upload>
            </el-form-item>
            <div v-if="form.errors.length > 0" class="mt-30 left">
              <el-alert type="error" :title="$tc('validateForm')">
                <ul>
                  <li v-for="(item, index) in form.errors" :key="index">
                    <span style="font-weight: bold">{{
                      item.field ? `${$tc(item.field)}: ` : ''
                    }}</span>
                    {{ item.message }}
                  </li>
                </ul>
              </el-alert>
            </div>
            <el-form-item class="mt-30">
              <div class="register-text-helper text-center">
                Al crear tu cuenta aceptas nuestros
                <div class="register-text-helper text-center">
                  <a
                    href="https://s3.amazonaws.com/cdn.aliaddo.com/contrato-de-prestacion-de-servicios-aliaddo-v28-01-2022.docx.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Términos y condiciones</a>
                  -
                  <a
                    href="https://aliaddo.com/privacidad/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Política de Tratamiento de Datos</a>
                </div>
              </div>
              <div class="register-text-helper text-center">
                {{ $tc('yourIp') }}: {{ form.model.ipAddress }}
              </div>
            </el-form-item>
            <el-form-item
              v-if="
                company.companyServiceFrequencyBilling &&
                +company.companyServiceFrequencyBilling.length &&
                +company.planId
              "

              class="mt-2"
            >
              <el-button class="btn btn-select" @click.prevent="onRegister">
                <span>
                  {{ $tc('createYourAccount', 2) }}
                </span>
              </el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
    <el-dialog
      :title="$tc('add', 1)"
      :visible.sync="dialog.address.visible"
      width="45% "
      append-to-body
    >
      <form-person-address
        v-if="dialog.address.visible"
        ref="formPersonAddress"
        :item.sync="model.address"
        @created="onAddAddress"
      ></form-person-address>
    </el-dialog>
  </div>
</template>
<script>
import axios from 'axios'

// #region  app ...
import calculateIdentificationCheck from '@/helpers/identification-check'
import { validateForm } from '@/helpers/messenger'
import { fieldRequiredByBlur } from '@/helpers/validations'
import { i18n } from '@/i18n'
import create from '@/views/common/create'
import dialog, { showDialog } from '@/views/common/dialog'
import form from '@/views/common/form'
import model from './model'
import rules from './rules-user'

// #endregion

export default {
  components: {
    selectInstitutions: () => import('@/components/selects/select-institutions')
  },
  extends: create('companies', { model }),
  props: {
    company: { type: Object, required: true },
    service: { type: Object, required: true },
    plan: { type: Object, required: false, default: () => ({}) },
    serviceTaxValue: { type: Number, required: false, default: 0 },
    serviceAmount: { type: Number, required: false, default: 0 },
    serviceAmountTotal: { type: Number, required: false, default: 0 }
  },
  data() {
    return {
      form: form({ model, rules }),
      dialog: {
        address: dialog()
      },
      list: {
        files: [],
        optionsIdentifications: []
      },
      loading: {
        personAddress: false
      },
      model: {
        response: {},
        registerSuccess: false,
        userExists: {}
      }
    }
  },
  computed: {
    uriStudentCardFile() {
      const uriBase =
        process.env.NODE_ENV === 'production'
          ? 'https://hub.aliaddo.net/api/v1/'
          : 'https://localhost:44358/api/v1/'

      // return `${uriBase}/companies/${model.response.personId}/rut`
      return `${uriBase}companies/student-card/file`
    },
    isAliaddo: {
      get(_this) {
        return _this.form.model.ipAddress === '186.31.27.24'
      }
    },
    labelIdentificationNumber: {
      get(_this) {
        if (_this.form.model.company.identificationTypeCode === 'CC') {
          return i18n.tc('identificationCc')
        }
        if (_this.form.model.company.identificationTypeCode === 'NIT') {
          return i18n.tc('identificationNit')
        }
        if (_this.form.model.company.identificationTypeCode === 'TE') {
          return i18n.tc('identificationTe')
        }
        if (_this.form.model.company.identificationTypeCode === 'PS') {
          return i18n.tc('identificationPs')
        }

        return i18n.tc('identificationYourCompany')
      }
    }
  },
  mounted() {
    this.getIp()
    this.fillOptions()

    // this.recaptcha()
  },
  methods: {
    fillCompanyName() {
      const _this = this
      if (_this.form.model.company.identificationTypeCode === 'CC') {
        _this.form.model.company.companyName = _this.form.model.userFullName
      }
    },
    showDialog,
    onChangeUpload(file) {
      this.form.model.studentCard = file
    },
    onChangeEmail(event) {
      const _this = this
      if (event && +event.length > 0) {
        _this.form.loading = true
        _this.$http
          .get(`users/${event}/findByEmail`)
          .then(result => {
            _this.model.userExists = result.data
            if (result.data) {
              _this.form.model = {
                ..._this.form.model,
                ...{
                  userName: result.data.userName

                  // phone: result.data.userPhone
                }
              }
            }
          })
          .finally(() => {
            _this.form.loading = false
          })
      } else {
        _this.form.model = {
          ..._this.form.model,
          ...{
            userName: '',
            phone: ''
          }
        }
        _this.model.userExists = {}
      }
    },
    onChangeIdentification() {
      const _this = this
      _this.form.model.company.companyIdentificationCheck = calculateIdentificationCheck(
        _this.form.model.company.companyIdentification
      )
      _this.onValidateIdentificationPattern('13')
    },
    onGoToService() {
      const _this = this
      if (process.env.NODE_ENV === 'production') {
        window.location.replace(`https://${this.service.serviceDomain}`, '_blank')
      } else if ([2, 11].includes(_this.service.id)) {
        window.location.replace('https://aliaddo-test-erp.firebaseapp.com', '_blank')
      } else if ([10].includes(_this.service.id)) {
        window.location.replace('https://aliaddo-test-hrm.firebaseapp.com', '_blank')
      } else if ([7, 12].includes(_this.service.id)) {
        window.location.replace('https://aliaddo-test-isv.firebaseapp.com', '_blank')
      }
    },
    async onRegister() {
      const _this = this
      _this.form.errors = []
      const promiseFormUser = new Promise(resolve => {
        _this.$refs.formUser.validate((valid, errors) => {
          for (const attribute in errors) {
            errors[attribute].forEach(element => {
              _this.form.errors.push({
                field: element.field,
                message: element.message
              })
            })
          }
          resolve(valid)
        })
      })
      promiseFormUser
        .then(valid => {
          if (!valid) {
            validateForm()

            return
          }
          const userFullNameParts = _this.form.model.fullName.trim().split(' ')
          if (userFullNameParts.length >= 4) {
            _this.form.model.firstName = `${userFullNameParts[0].trim()} ${userFullNameParts[1].trim()}`
            _this.form.model.lastName = `${userFullNameParts[2].trim()} ${userFullNameParts[3].trim()}`
          } else if (userFullNameParts.length === 3) {
            _this.form.model.firstName = `${userFullNameParts[0].trim()}`
            _this.form.model.lastName = `${userFullNameParts[1].trim()} ${userFullNameParts[2].trim()}`
          } else if (userFullNameParts.length === 2) {
            _this.form.model.firstName = `${userFullNameParts[0].trim()}`
            _this.form.model.lastName = `${userFullNameParts[1].trim()}`
          } else if (userFullNameParts.length === 1) {
            _this.form.model.firstName = _this.form.model.fullName.trim()
            _this.form.model.lastName = _this.form.model.fullName.trim()
          }
          _this.form.model = {
            ..._this.form.model,
            userFirstName: _this.form.model.firstName,
            userLastName: _this.form.model.lastName,
            userName: _this.form.model.email
              // eslint-disable-next-line no-useless-escape
              .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
              .trim()
              .slice(0, 23),
            userEmail: _this.form.model.email.trim(),
            ...{
              company: {
                ..._this.company,
                ..._this.form.model.company,
                companyName: _this.form.model.company.companyName.trim(),
                companyIdentification: _this.form.model.company.companyIdentification.trim()
              }
            },
            seller: _this.$route.query.seller,
            source: _this.$route.query.source
          }
          if (_this.$route.query.source === 'crm' || _this.$route.query.seller) {
            _this.form.model.company.companyServiceSource = 'Crm'
          } else {
            _this.form.model.company.companyServiceSource = 'Site'
          }
          const formData = new FormData()
          formData.append('signup', JSON.stringify(_this.form.model))

          console.log(
            '%c⧭',
            'color: #cc0088',
            _this.$refs.upload,
            _this.$refs.upload.uploadFiles[0],
            _this.$refs.upload.uploadFiles.length
          )
          if (_this.$refs.upload.uploadFiles.length > 0) {
            formData.append('file', _this.$refs.upload.uploadFiles[0].raw)
          }

          _this.form.loading = true

          _this.$gtag.event('lead', {
            ecommerce: {
              purchase: {
                actionField: {
                  // Transaction ID. Required for purchases and refunds.
                  id: _this.form.model.company.companyIdentification,
                  affiliation: _this.plan.planName,

                  // Total transaction value (incl. tax and shipping)
                  revenue: _this.serviceAmountTotal,
                  tax: _this.serviceTaxValue,
                  shipping: _this.serviceAmount,
                  coupon: ''
                },
                products: [
                  {
                    // List of productFieldObjects.
                    name: _this.plan.planName, // Name or ID is required.
                    id: _this.plan.id,
                    price: _this.serviceAmount,
                    brand: 'Aliaddo',
                    category: 'Service',
                    quantity: 1,
                    coupon: '' // Optional fields may be omitted or set to empty string.
                  }
                ]
              }
            }
          })

          _this.$gtag.event('lead', {
            affiliation: _this.plan.planName,
            value: _this.form.model.serviceAmountTotal,
            planPricing: _this.form.model.serviceAmountTotal,
            currency: 'COP'
          })

          _this.$http
            .post('companies/students', formData)
            .then(() => {
              _this.model.registerSuccess = true
            })
            .catch(() => {
              setTimeout(() => {
                _this.form.loading = false
              }, 1000)
            })
            .finally(() => {
              setTimeout(() => {
                _this.form.loading = false
              }, 1000)
            })
        })
        .catch(error => {
          validateForm(error.stack)
        })
    },
    onValidateIdentificationPattern(value) {
      const _this = this
      _this.form.rules.company.fields.companyIdentification = []
      if (value === '41' || value === '42' || value === '50') {
        _this.form.rules.company.fields.companyIdentification.push(fieldRequiredByBlur, {
          message: _this.$tc('fieldHasInvalidCharacter'),
          // eslint-disable-next-line no-useless-escape
          pattern: /^[a-zA-Z0-9_\-]{4,16}$/,
          required: true,
          trigger: 'blur'
        })
      } else if (value === '13') {
        _this.form.rules.company.fields.companyIdentification.push(fieldRequiredByBlur, {
          message: _this.$tc('fieldHasInvalidCharacter'),
          // eslint-disable-next-line no-useless-escape
          pattern: /^[1-9][0-9]{3,9}$/,
          required: true,
          trigger: 'blur'
        })
      } else if (value === '21') {
        _this.form.rules.company.fields.companyIdentification.push(fieldRequiredByBlur, {
          message: _this.$tc('fieldHasInvalidCharacter'),
          // eslint-disable-next-line no-useless-escape
          pattern: /^([a-zA-Z]{1,5})?[1-9][0-9]{3,7}$/,
          required: true,
          trigger: 'blur'
        })
      } else if (value === '12') {
        _this.form.rules.company.fields.companyIdentification.push(fieldRequiredByBlur, {
          message: _this.$tc('fieldHasInvalidCharacter'),
          // eslint-disable-next-line no-useless-escape
          pattern: /^[1-9][0-9]{4,11}$/,
          required: true,
          trigger: 'blur'
        })
      } else if (value === '31') {
        _this.form.rules.company.fields.companyIdentification.push(fieldRequiredByBlur, {
          message: _this.$tc('fieldHasInvalidCharacter'),
          // eslint-disable-next-line no-useless-escape
          pattern: /^[1-9]\d{6,9}$/,
          required: true,
          trigger: 'blur'
        })
      } else {
        _this.form.rules.company.fields.companyIdentification.push(fieldRequiredByBlur)
      }
    },
    fillOptions() {
      const _this = this
      _this.$http.get('identificationTypes').then(response => {
        if (response.body.length > 0) {
          _this.list.optionsIdentifications = response.body.filter(item => ['31', '13'].includes(item.id)
          )
        } else {
          _this.list.optionsIdentifications = []
        }
      })
    },
    getIp() {
      axios.get('https://api.ipify.org?format=json').then(response => {
        this.form.model.ipAddress = response.data.ip
      })
    }
  }
}
</script>
<style lang="scss" scoped>
div {
  &::v-deep .el-upload-dragger {
    width: 100% !important;
    height: 80px !important;
    padding: 0.4em;
  }
}
div {
  &::v-deep .el-upload--text {
    width: 100%;
    height: 80px !important;
  }
}
</style>
<style lang="scss" scoped>
@import '@/assets/scss/transactions/register.scss';
@import '@/assets/scss/transactions/personalize.scss';
</style>
